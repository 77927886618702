"use client";
import CookieSpool from '@/components/CookieSpool/CookieSpool';
import { GoogleTagManager } from '@next/third-parties/google';
import { useEffect, useState } from 'react';

export default function GTM() {
  const [ hostMain, setHostMain ] = useState<boolean>();
  const [ gtmId, setGtmId ] = useState<string>();
  const [ csDelay, setCsDelay ] = useState<boolean>();

  useEffect(() => {
    const isDopi = (window?.location?.host ?? window?.location?.hostname ?? '') === 'www.dopinghafiza.com';
    if(isDopi !== hostMain) setHostMain(isDopi);

    setGtmId(isDopi ? (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? '') : (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_SECONDARY_ID ?? ''));
    setTimeout(() => {
      setCsDelay(true);
    }, 1000);
  }, [ hostMain ]);

  return (
    <>
      {
        !!gtmId && (
          <>
            <GoogleTagManager gtmId={gtmId} />
          </>
        )
      }

      { hostMain && csDelay && <CookieSpool /> }
    </>
  );
}
