"use client";
import './cookie-spool.css';
import './cookie-spool-local-preferences.css';
import Script from 'next/script';

export default function CookieSpool() {
  return (
    <>
      <Script id="cookie-spool-main" src={(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/cookiespool/cookie-spool.js'} />
      <Script id="cookie-spool-settings" src={(process.env.NEXT_PUBLIC_ASSET_ROOT ?? '') + '/assets/public_assets/cookiespool/cookiespool-consent-settings.js'} />
    </>
  );
}
